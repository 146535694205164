import React, { lazy, Suspense, useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { ClimbingBoxLoader } from 'react-spinners'

import { ThemeProvider } from '@material-ui/styles'

import MuiTheme from './theme'

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints'

// Pages

import PageError404 from './example-pages/PageError404'
import PageError500 from './example-pages/PageError500'
import PageError505 from './example-pages/PageError505'
const Login = lazy(() => import('./pages/auth/Login'))
const Logout = lazy(() => import('./pages/auth/Logout'))
const PrivacyPolicy = lazy(() => import('./pages/auth/PrivacyPolicy'))
const RecoverPassword = lazy(() => import('./pages/auth/RecoverPassword'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Customers = lazy(() => import('./pages/customers/Customers'))
const Drivers = lazy(() => import('./pages/drivers/Drivers'))
const Jobs = lazy(() => import('./pages/jobs/Jobs'))
const AddCustomer = lazy(() => import('./pages/customers/AddCustomer'))
const AddDriver = lazy(() => import('./pages/drivers/AddDriver'))
const AddJob = lazy(() => import('./pages/jobs/AddJob'))

const Routes = () => {
  const location = useLocation()

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  }

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300)
      return () => {
        clearTimeout(timeout)
      }
    }, [])

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key='loading'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className='d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3'>
                <div className='d-flex align-items-center flex-column px-4'>
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className='text-muted font-size-xl text-center pt-3'>
                  Please wait while we load the live preview examples
                  <span className='font-size-lg d-block text-dark'>
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from='/' to='/login' />
            {/* <Route path={['/Overview']}>
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Overview" component={Overview} />
                    </motion.div>
                  </Switch>
                </PresentationLayout>
              </Route> */}
            <Route
              path={[
                '/dashboard',
                '/dashboard/employee',
                '/customers',
                '/customer/jobs/111',
                '/customer/jobs/111',
                '/customer/tickets/111',
                '/customer/view-ticket/111',
                '/drivers',
                '/archive-drivers',
                '/driver/jobs/111',
                '/driver/tickets/111',
                '/driver/view-ticket/111',
                '/jobs',
                '/create-customer',
                '/create-driver',
                '/create-job',
                '/view-customer',
                '/view-driver',
                '/view-job',
                '/edit-customer',
                '/edit-driver',
                '/edit-job',
                '/allocate-job',
                '/archive-jobs',
                '/job/view-tickets',
                '/job/assigned-drivers',
                '/job/assigned-brokers',
                '/brokers',
                '/create-broker',
                '/edit-broker',
                '/view-broker',
                '/tickets',
                '/view-ticket',
                '/invoices',
                '/invoice/wallet',
                '/invoice/wallet/tickets',
                '/employees',
                '/create-employee',
                '/edit-employee',
                '/view-employee'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    {/* <Route path="/dashboard" component={Dashboard} /> */}
                    <Route
                      path='/dashboard'
                      component={lazy(() => import('./pages/dashboard/DashboardTabs'))}
                      exact
                    />
                    {/* <Route
                      path='/dashboard'
                      component={lazy(() => import('./pages/Dashboard1'))}
                      exact
                    /> */}
                    <Route
                      path='/dashboard/employee'
                      component={lazy(() => import('./pages/dashboard/EmployeeDashboard'))}
                      exact
                    />
                    <Route path='/customers' component={Customers} />
                    <Route path='/drivers' component={Drivers} />
                    <Route path='/jobs' component={Jobs} />
                    <Route
                      path='/create-customer'
                      component={lazy(() =>
                        import('./pages/customers/AddCustomer')
                      )}
                    />
                    <Route
                      path='/customer/jobs/:id'
                      component={lazy(() =>
                        import('./pages/customers/CustomerJobs')
                      )}
                    />
                    <Route
                      path='/customer/tickets/:id'
                      component={lazy(() =>
                        import('./pages/customers/CustomerTickets')
                      )}
                    />
                    <Route
                      path='/customer/view-ticket/:id'
                      component={lazy(() =>
                        import('./pages/customers/ViewCustomerTicket')
                      )}
                    />
                    <Route
                      path='/archive-drivers'
                      component={lazy(() =>
                        import('./pages/drivers/ArchivedDrivers')
                      )}
                    />
                    <Route
                      path='/create-driver'
                      component={lazy(() =>
                        import('./pages/drivers/AddDriver')
                      )}
                    />
                    <Route
                      path='/driver/jobs/:id'
                      component={lazy(() =>
                        import('./pages/drivers/DriverJobs')
                      )}
                    />
                    <Route
                      path='/driver/tickets/:id'
                      component={lazy(() =>
                        import('./pages/drivers/DriverTickets')
                      )}
                    />
                    <Route
                      path='/driver/view-ticket/:id'
                      component={lazy(() =>
                        import('./pages/drivers/ViewDriverTicket')
                      )}
                    />
                    <Route
                      path='/create-job'
                      component={lazy(() => import('./pages/jobs/AddJob'))}
                    />
                    <Route
                      path='/view-customer/:id'
                      component={lazy(() =>
                        import('./pages/customers/ViewCustomer')
                      )}
                    />
                    <Route
                      path='/view-driver/:id'
                      component={lazy(() =>
                        import('./pages/drivers/ViewDriver')
                      )}
                    />
                    <Route
                      path='/view-job/:id'
                      component={lazy(() => import('./pages/jobs/ViewJob'))}
                    />
                    <Route
                      path='/edit-customer/:id'
                      component={lazy(() =>
                        import('./pages/customers/EditCustomer')
                      )}
                    />
                    <Route
                      path='/edit-driver/:id'
                      component={lazy(() =>
                        import('./pages/drivers/EditDriver')
                      )}
                    />
                    <Route
                      path='/edit-job/:id'
                      component={lazy(() => import('./pages/jobs/EditJob'))}
                    />
                    <Route
                      path='/allocate-job/:id'
                      component={lazy(() => import('./pages/jobs/AllocateJob'))}
                    />
                    <Route
                      path='/archive-jobs'
                      component={lazy(() =>
                        import('./pages/jobs/ArchivedJobs')
                      )}
                    />
                    <Route
                      path='/job/view-tickets/:id'
                      component={lazy(() => import('./pages/jobs/JobTickets'))}
                    />
                    <Route
                      path='/job/assigned-drivers/:id'
                      component={lazy(() =>
                        import('./pages/jobs/AssignedDrivers')
                      )}
                    />
                    <Route
                      path='/job/assigned-brokers/:id'
                      component={lazy(() =>
                        import('./pages/jobs/AssignedBrokers')
                      )}
                    />
                    <Route
                      path='/brokers'
                      component={lazy(() => import('./pages/brokers/Brokers'))}
                    />
                    <Route
                      path='/create-broker'
                      component={lazy(() =>
                        import('./pages/brokers/AddBroker')
                      )}
                    />
                    <Route
                      path='/edit-broker/:id'
                      component={lazy(() =>
                        import('./pages/brokers/EditBroker')
                      )}
                    />
                    <Route
                      path='/view-broker/:id'
                      component={lazy(() =>
                        import('./pages/brokers/ViewBroker')
                      )}
                    />
                    <Route
                      path='/tickets'
                      component={lazy(() => import('./pages/tickets/Tickets'))}
                    />
                    <Route
                      path='/view-ticket/:id'
                      component={lazy(() =>
                        import('./pages/tickets/ViewTicket')
                      )}
                    />
                    <Route
                      path='/invoices'
                      component={lazy(() =>
                        import('./pages/invoices/Invoices')
                      )}
                    />
                    <Route
                      path='/invoice/wallet/:id'
                      exact
                      component={lazy(() => import('./pages/invoices/Wallet'))}
                    />
                    <Route
                      path='/invoice/wallet/tickets/:iid/:jid'
                      component={lazy(() => import('./pages/invoices/Tickets'))}
                    />
                    <Route
                      path='/employees'
                      component={lazy(() =>
                        import('./pages/employees/Employees')
                      )}
                    />
                    <Route
                      path='/create-employee'
                      component={lazy(() =>
                        import('./pages/employees/AddEmployee')
                      )}
                    />
                    <Route
                      path='/edit-employee/:id'
                      component={lazy(() =>
                        import('./pages/employees/EditEmployee')
                      )}
                    />
                    <Route
                      path='/view-employee/:id'
                      component={lazy(() =>
                        import('./pages/employees/ViewEmployee')
                      )}
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            <Route path={[]}>
              <CollapsedSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    {' '}
                  </motion.div>
                </Switch>
              </CollapsedSidebar>
            </Route>

            <Route
              path={[
                '/login',
                '/logout',
                '/recover-password',
                '/privacy-policy',
                '/PageError404',
                '/PageError500',
                '/PageError505'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path='/login' component={Login} />
                    <Route path='/logout' component={Logout} />
                    <Route
                      path='/recover-password'
                      component={RecoverPassword}
                    />
                    <Route
                      path='/privacy-policy'
                      component={PrivacyPolicy}
                    />
                    <Route path='/PageError404' component={PageError404} />
                    <Route path='/PageError500' component={PageError500} />
                    <Route path='/PageError505' component={PageError505} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default Routes
